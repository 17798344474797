import React, { useEffect, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactGA from "react-ga";
import NotFoundBlock from "../Components/NotFoundBlock";
import PrivateRoute from "../lib/PrivateRoute";
import { defaultStyle } from "../theme";

const ShopsPage = lazy(() => import("../Pages/ShopsPage"));
const ShopPageGeneric = lazy(() => import("../Pages/ShopPageGeneric"));
const Home = lazy(() => import("../Pages/Home"));
const BatchPage = lazy(() => import("../Pages/BatchManagement/BatchPage"));
const BatchDetailsPage = lazy(() =>
  import("../Pages/BatchManagement/BatchDetailsPage")
);
const UsersPage = lazy(() => import("../Pages/Admin/UsersPage"));
const OrderPortfolioProvider = lazy(() =>
  import("../Pages/OrderPortfolio/OrderPortfolioProvider")
);
const OrderPage = lazy(() => import("../Pages/OrderPortfolio/OrderPage"));
const StockProvider = lazy(() => import("../Pages/Stock/StockProvider"));
const StockPage = lazy(() => import("../Pages/Stock/StockPage"));
const ContactProvider = lazy(() => import("../Pages/Contact/ContactProvider"));
const ContactPage = lazy(() => import("../Pages/Contact/ContactPage"));
const ProGroupProvider = lazy(() =>
  import("../Pages/ProGroup/ProGroupProvider")
);
const ProGroupPage = lazy(() => import("../Pages/ProGroup/ProGroupPage"));
const ContactDetailsPage = lazy(() =>
  import("../Pages/Contact/ContactDetailsPage")
);
const ProGroupDetailsPage = lazy(() =>
  import("../Pages/ProGroup/ProGroupDetailsPage")
);
const OrderPortfolioDetailPage = lazy(() =>
  import("../Pages/OrderPortfolio/OrderPortfolioDetailPage")
);
const StockDetailPage = lazy(() => import("../Pages/Stock/StockDetailPage"));
const CatalogPage = lazy(() => import("../Pages/Catalog/CatalogPage"));
const CatalogDetailPage = lazy(() =>
  import("../Pages/Catalog/CatalogDetailPage")
);
const CatalogProvider = lazy(() => import("../Pages/Catalog/CatalogProvider"));
const CreditPage = lazy(() => import("../Pages/CreditPage"));

const IOTDevicesProvider = lazy(() =>
  import("../Pages/Iot/IOTDevicesProvider")
);
const RecipePage = lazy(() => import("../Pages/Recipe/RecipePage"));
const ChartPage = lazy(() => import("../Pages/Iot/ChartPage"));
const DevicesListPage = lazy(() => import("../Pages/Iot/DevicesListPage"));
const GravityAdjustToolPage = lazy(() =>
  import("../Pages/Tools/GravityAdjustToolPage")
);
const YieldToolPage = lazy(() => import("../Pages/Tools/YieldPage"));
const SanitiserPage = lazy(() => import("../Pages/Tools/SanitiserPage"));
const YeastToolPage = lazy(() => import("../Pages/Tools/YeastToolPage"));
const ToolList = lazy(() => import("../Components/ToolList/ToolList"));
const LearntList = lazy(() => import("../Components/LearntList/LearntList"));
const MyBreweryList = lazy(() =>
  import("../Components/MyBreweryList/MyBreweryList")
);
const AlcoholLevelPage = lazy(() => import("../Pages/Tools/AlcoholLevelPage"));
const BottlingPage = lazy(() => import("../Pages/Tools/BottlingPage"));
const DensityToolPage = lazy(() => import("../Pages/Tools/DensityToolPage"));
const EBCToolPage = lazy(() => import("../Pages/Tools/EBCToolPage"));
const ConversionToolPage = lazy(() =>
  import("../Pages/Tools/ConversionToolPage")
);
const TheoricalDensityTool = lazy(() =>
  import("../Pages/Tools/TheoricalDensityTool")
);
const IBUTool = lazy(() => import("../Pages/Tools/IBUTool"));
const VolumeToolPage = lazy(() => import("../Pages/Tools/VolumeToolPage"));
const RecipesProvider = lazy(() => import("../Pages/Recipe/RecipesProvider"));
const RecipesPage = lazy(() => import("../Pages/Recipe/RecipesPage"));
const CGUPage = lazy(() => import("../Pages/CGUPage"));
const ReleasePage = lazy(() => import("../Pages/ReleasePage"));
const RenewAuth = lazy(() => import("../Pages/RenewAuth"));
const ProConfigPage = lazy(() => import("../Pages/ProConfig/ProConfigPage"));

const ShopAdminDetails = lazy(() =>
  import("../Pages/ShopsPageAdmin/ShopAdminDetails")
);

function getAllUrlParams(url) {
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  var obj = {};

  if (queryString) {
    queryString = queryString.split("#")[0];

    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      var a = arr[i].split("=");

      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      if (paramName.match(/\[(\d+)?\]$/)) {
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];
        if (paramName.match(/\[\d+\]$/)) {
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          obj[key].push(paramValue);
        }
      } else {
        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

const token =
  getAllUrlParams(window.location.href).token ||
  localStorage.getItem("IOTDevice");
const from =
  getAllUrlParams(window.location.href).to || localStorage.getItem("IOTFrom");
const to =
  getAllUrlParams(window.location.href).from || localStorage.getItem("IOTTo");

ReactGA.initialize("UA-35035484-3");

const AppRoutes = () => {
  let history = useHistory();
  const defaultClasses = defaultStyle();

  useEffect(() => {
    ReactGA.pageview(history.location.pathname);
    let regex1 = RegExp("(^/$)|(^.*/login$)");

    if (!regex1.test(history.location.pathname)) {
      localStorage.setItem(
        "lastPage",
        history.location.pathname + history.location.search
      );
    }
  }, [history.location.pathname]);

  return (
    <Switch>
      <Route
        exact
        path={[
          `${process.env.PUBLIC_URL}/home`,
          `${process.env.PUBLIC_URL}/`,
          `${process.env.PUBLIC_URL}/login`,
        ]}
        render={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <Home />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/credit`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <CreditPage />{" "}
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/renewAuth`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <RenewAuth />{" "}
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/cgu`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <CGUPage />{" "}
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/release`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ReleasePage />{" "}
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/pro-group`}
        roles={["admin"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ProGroupProvider history={history}>
              <ProGroupPage />
            </ProGroupProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        path={`${process.env.PUBLIC_URL}/user-admin`}
        roles={["admin"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <UsersPage token={token} from={from} to={to} />
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/pro-group/:id`}
        roles={["admin"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ProGroupProvider history={history}>
              <ProGroupDetailsPage />
            </ProGroupProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/order-portfolio`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <OrderPortfolioProvider history={history} {...props}>
              <OrderPage />
            </OrderPortfolioProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/order-portfolio/:id`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <OrderPortfolioProvider history={history}>
              <OrderPortfolioDetailPage />
            </OrderPortfolioProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/catalog`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <CatalogProvider history={history} {...props}>
              <CatalogPage />
            </CatalogProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/shopAdmin/:id`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ShopAdminDetails />
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/catalog/:id`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <CatalogProvider history={history}>
              <CatalogDetailPage />
            </CatalogProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/stock`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <StockProvider history={history}>
              <StockPage />
            </StockProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/stock/:id`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <StockProvider history={history}>
              <StockDetailPage />
            </StockProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/contact`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ContactProvider history={history}>
              <ContactPage />
            </ContactProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/contact/:id`}
        offers={["premium-pro"]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ContactProvider history={history}>
              <ContactDetailsPage />
            </ContactProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/batch`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <BatchPage token={token} from={from} to={to} />
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/batch/:id`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <BatchDetailsPage
              url={process.env.REACT_APP_API_URL + "/batches"}
              token={token}
              from={from}
              to={to}
            />
          </Suspense>
        )}
      />

      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/devices`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <IOTDevicesProvider>
              <DevicesListPage />
            </IOTDevicesProvider>
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/brewery-conf`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ProConfigPage />
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/device/:deviceId/:type`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ChartPage />
          </Suspense>
        )}
      />
      {/* RECIPE SECTION */}
      <Route
        exact
        path={[
          `${process.env.PUBLIC_URL}/recipes`,
          `${process.env.PUBLIC_URL}/recipes-pro`,
        ]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <RecipesProvider>
              <RecipesPage />
            </RecipesProvider>
          </Suspense>
        )}
      />
      <Route
        exact
        path={[
          `${process.env.PUBLIC_URL}/recipes/:id`,
          `${process.env.PUBLIC_URL}/recipes-builder/:id`,
        ]}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <RecipePage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/yeast-tool`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <div>
              <YeastToolPage />
            </div>
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/gravity-adjust-tool`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <GravityAdjustToolPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/convertisseur`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ConversionToolPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/alcohol-tool`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <AlcoholLevelPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/yieldTool`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <YieldToolPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/sanitiser`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <SanitiserPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/bottling`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <BottlingPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/density`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <DensityToolPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/ebc-tool`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <EBCToolPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/ibu-tool`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <IBUTool />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/tools`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ToolList />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/learn`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <LearntList />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/myBrewery`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <MyBreweryList />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/theorical-density`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <TheoricalDensityTool />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/shop`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ShopPageGeneric forcedId="brasseband" />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/shops`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ShopsPage />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/shops/:id`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ShopPageGeneric />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/shops-embedded/:id`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <ShopPageGeneric />
          </Suspense>
        )}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/volume-tool`}
        component={(props) => (
          <Suspense
            fallback={
              <div className={defaultClasses.progressLazy}>
                <CircularProgress />
              </div>
            }
          >
            <VolumeToolPage />
          </Suspense>
        )}
      />
      <Route path="*" component={() => <NotFoundBlock />} />
    </Switch>
  );
};

export { AppRoutes };

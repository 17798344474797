import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "./lib/react-auth0-wrapper";
import * as serviceWorker from "./serviceWorker";
import './i18n';

import App from "./App";
import history from "./history";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

const GlobalContainer = () => {
  const [isServiceWorkerUpdated, setIsServiceWorkerUpdated] = useState(false);
  const [reg, setReg] = useState(false);

 /* serviceWorker.register({
    onSuccess: () => console.log("onSuccess"),
    onUpdateFound: (reg) => {
      console.log("onUpdate", reg);
      setReg(reg);
      setIsServiceWorkerUpdated(true);
    },
  });*/
  serviceWorker.unregister();

  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  const updateServiceWorker = () => {
    const registrationWaiting = reg.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_DOMAINE}
      client_id={process.env.REACT_APP_CLIENT_ID}
      redirect_uri={process.env.REACT_APP_CALLBACK}
      onRedirectCallback={onRedirectCallback}
    >
      {isServiceWorkerUpdated}
      <Snackbar
        open={isServiceWorkerUpdated}
        message="Archived"
        action={
          <Button color="inherit" size="small" onClick={updateServiceWorker}>
            Undo
          </Button>
        }
      />

      <App />
    </Auth0Provider>
  );
};

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    //include: [/^.*$/],
    exclude: [
      /^Switch/,
      /^Router/,
      /^Route/,
      /^Connect/,
      /^ButtonBase/,
      /^ValueLabel/,
      /^ValueLabel/,
      /^Toolbar/,
      /^Paper/,
      /^IconButton/,
      /^NoSsr/,
      /^AppBar/,
      /^CssBaseline/,
      /^Avatar/,
      /^WithStyles*/
    ],
  });
}

ReactDOM.render(<GlobalContainer />, document.getElementById("root"));

export const generateErrorObject=(code) => {
    
    if(code===403)
    {
        return {status:403, message: "Oups, Vous n'êtes pas autorisé à accéder à cette page."}
    }
    if(code === 404)
    {
        return {status:code, message: "Oups, cette page n'existe pas."}
    }
}
export default {
  primary: {
    light: "#ffca87",
    main: "#ffbd6a",
    dark: "#ffbd6a",
    contrastText: "#000"
  },
  secondary: { main: "#484848", light: "#696969", dark: "#484848" },
  info: { main: "#484848" },
  tertiary: { main: "#a7a5a2", light: "#a7a5a2", dark: "#a7a5a2" },
  danger: {
    main: "rgba(224, 38, 38, 0.77);"
  },
  background: { main: "#fcfcfc", light: "#e0e0e0", dark: "#2f2f2f" },
  onHeader: { main: "#dedede" },
  success: { main: "#11cb5f", background: "#fff" },
  warning: { main: "#11cb5f", background: "#ffa100" },
  error: { main: "#cb1111", background: "#d32f2f" }
};

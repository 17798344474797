import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import LoginButton from "../LoginButton/button";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useAuth0 } from "../../lib/react-auth0-wrapper";
import List from "@material-ui/core/List";
import SettingsIcon from '@material-ui/icons/Settings';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import ListSubheader from "@material-ui/core/ListSubheader";
import Badge from "@material-ui/core/Badge";

import HomeIcon from "@material-ui/icons/Home";
import DevicesIcon from "@material-ui/icons/Devices";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import BuildIcon from "@material-ui/icons/Build";
import { VintageIcon } from "../../icons/VintageIcon";
import { useHistory } from "react-router-dom";

import Assignment from "@material-ui/icons/Assignment";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ContactMail from "@material-ui/icons/ContactMail";
import MenuBook from "@material-ui/icons/MenuBook";
import StorefrontIcon from "@material-ui/icons/Storefront";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  menuIcon: {
    color: "#757575",
  },

  drawerPaper: {
    width: drawerWidth,
    background: "#484848",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "calc(100% -  64px)",
      marginTop: 64,
      zIndex: 1,
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% -  56px)",
      marginTop: 56,
      zIndex: 1,
    },
  },
}));

const ItemMenu = ({ closeDrawer, icon, path, label, isNew }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ListItem
      button
      key={label}
      onClick={() => {
        closeDrawer();
        history.push(`${process.env.PUBLIC_URL}/${path}`);
      }}
    >
      <ListItemIcon className={classes.menuIcon}>{icon}</ListItemIcon>
      {isNew ? (
        <Badge
          color="secondary"
          badgeContent="New"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ListItemText primary={label} />
        </Badge>
      ) : (
        <ListItemText primary={label} />
      )}
    </ListItem>
  );
};

const PageDrawer = ({
  variant,
  isOpen,
  toggleDrawer,
  closeDrawer,
  isPremiumPro,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  let { loginWithRedirect, logout, roles, isAuthenticated } = useAuth0();

  return (
    <nav className={classes.drawer}>
      <Drawer
        variant={variant}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={isOpen}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          style={{
            overflow: "auto",
            color: "white",
          }}
        >
          <List>
            <ItemMenu
              closeDrawer={closeDrawer}
              icon={<HomeIcon />}
              path="home"
              label="Accueil"
            />

            <ItemMenu
              closeDrawer={closeDrawer}
              icon={<Assignment />}
              path="recipes"
              label="Recettes"
            />

            <ItemMenu
              closeDrawer={closeDrawer}
              icon={<BuildIcon />}
              path="tools"
              label="Outils"
              isNew={true}
            />
            <ItemMenu
              closeDrawer={closeDrawer}
              icon={<StorefrontIcon />}
              path="shops"
              label="Les shops"
              isNew={true}
            />
          </List>
          {isAuthenticated ? (
            <Fragment>
              <Divider />
              <List
                subheader={
                  <ListSubheader
                    color="primary"
                    component="div"
                    id="nested-list-subheader"
                  >
                    Espace personnel{" "}
                  </ListSubheader>
                }
              >
                <ItemMenu
                  closeDrawer={closeDrawer}
                  icon={<VintageIcon color="#757575" />}
                  path="batch"
                  label="Mes brassins"
                  isNew={false}
                />
                <ItemMenu
                  closeDrawer={closeDrawer}
                  icon={<DevicesIcon />}
                  path="devices"
                  label="Appareils connectés"
                  isNew={false}
                />
              </List>

              {isPremiumPro ? (
                <Fragment>
                  <Divider />
                  <List
                    subheader={
                      <ListSubheader
                        color="primary"
                        component="div"
                        id="nested-list-subheader"
                      >
                        Espace brasserie (pro)
                      </ListSubheader>
                    }
                  >
                    <ItemMenu
                      closeDrawer={closeDrawer}
                      icon={<MenuBook />}
                      path="catalog"
                      label="Catalogue"
                      isNew={false}
                    />
                    <ItemMenu
                      closeDrawer={closeDrawer}
                      icon={<ShoppingCart />}
                      path="stock"
                      label="Stock"
                      isNew={false}
                    />
                    <ItemMenu
                      closeDrawer={closeDrawer}
                      icon={<ShoppingCart />}
                      path="order-portfolio"
                      label="Commandes"
                      isNew={false}
                    />
                    <ItemMenu
                      closeDrawer={closeDrawer}
                      icon={<ContactMail />}
                      path="contact"
                      label="Carnet d'adresse"
                      isNew={false}
                    />
                    <ItemMenu
                      closeDrawer={closeDrawer}
                      icon={<SettingsIcon />}
                      path="brewery-conf"
                      label="Configuration"
                      isNew={true}
                    />
                  </List>
                </Fragment>
              ) : (
                ""
              )}

              {roles && roles.includes("admin") ? (
                <Fragment>
                  <Divider />
                  <List
                    subheader={
                      <ListSubheader
                        color="primary"
                        component="div"
                        id="nested-list-subheader"
                      >
                        Administration
                      </ListSubheader>
                    }
                  >
                    <ItemMenu
                      closeDrawer={closeDrawer}
                      icon={<PowerIcon />}
                      path="user-admin"
                      label="Utilisateurs"
                      isNew={false}
                    />
                    <ItemMenu
                      closeDrawer={closeDrawer}
                      icon={<PowerIcon />}
                      path="pro-group"
                      label="Entreprises"
                      isNew={false}
                    />
                  </List>
                </Fragment>
              ) : (
                ""
              )}
              <Divider />
              <List>
                <ItemMenu
                  closeDrawer={logout}
                  icon={<PowerIcon />}
                  path="home"
                  label="Déconnexion"
                  isNew={false}
                />
              </List>
            </Fragment>
          ) : (
            ""
          )}
        </div>
        {!isAuthenticated ? (
          <div
            style={{
              margin: "20px",
              padding: "10px",
              border: "#272836 1px solid",
              borderRadius: "8px",
              background: "#929292",
            }}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{
                color: "white",
              }}
            >
              Découvrez plus de fonctionnalités en vous connectant !
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <LoginButton action={loginWithRedirect} label="Se connecter" />
            </div>
          </div>
        ) : (
          ""
        )}
      </Drawer>
    </nav>
  );
};

export default PageDrawer;

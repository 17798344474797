import React from "react";

import "./style.css";

const LoginButton = ({ action, label }) => (
  <button className="learn-more" onClick={action}>
    <span className="circle" aria-hidden="true">
      <span className="icon arrow"></span>
    </span>
    <span className="button-text">{label}</span>
  </button>
);

export default LoginButton;

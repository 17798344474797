import React, { useEffect, useState, Fragment } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import CssBaseline from "@material-ui/core/CssBaseline";

import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/styles";

import MenuIcon from "@material-ui/icons/Menu";
import logoBf from "../../assets/img/logoBeta.png";

import moment from "moment";
import { useAuth0 } from "../../lib/react-auth0-wrapper";
import withWidth, { isWidthDown, isWidthUp } from "@material-ui/core/withWidth";
import PageDrawer from "./PageDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  logoBf: {
    height: 50,
    margin: "auto",
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const MenuAndAppBar = (props) => {
  let { user, offers, roles } = useAuth0();
  const classes = useStyles();
  const [state, setState] = useState({
    isOpen: false,
    isPremiumPro: false,
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  console.log(user);
  useEffect(() => {
    setState({
      ...state,
      isPremiumPro:
        offers &&
        user &&
        user.proGroups &&
        user.proGroups.length > 0 &&
        Array.isArray(offers) &&
        offers.filter(
          (o) =>
            o.type === "premium-pro" &&
            moment(o.startDate).isBefore(new Date()) &&
            moment(o.endDate).isAfter(new Date())
        ).length > 0,
    });
  }, [offers, roles, user]);

  const toggleDrawer = () => {
    setState({ ...state, isOpen: !state.isOpen });
  };
  const closeDrawer = () => {
    setState({ ...state, isOpen: false });
  };

  return (
    <Fragment>
      <CssBaseline />
      <AppBar position="fixed">
        {process.env.REACT_APP_ENVNAME !== "Prod" ? (
          <div
            style={{
              position: "fixed",
              top: "10px",
              left: "100px",
              background: "#293094",
              zIndex: 9000,
              padding: "10px",
              color: "white",
            }}
          >
            {process.env.REACT_APP_ENVNAME}
          </div>
        ) : (
          ""
        )}
        <Toolbar>
          {!matches ? (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            ""
          )}

          <img src={logoBf} className={classes.logoBf} alt="logo" />
          <Avatar
            alt="Remy Sharp"
            src={user ? user.picture : ""}
            style={{ float: "right" }}
          />
        </Toolbar>
      </AppBar>
      <div>
        {matches ? (
          <Fragment>
            <PageDrawer
              variant="permanent"
              isOpen={state.isOpen}
              toggleDrawer={toggleDrawer}
              closeDrawer={closeDrawer}
              isPremiumPro={state.isPremiumPro}
            />
          </Fragment>
        ) : (
          <PageDrawer
            variant="temporary"
            isOpen={state.isOpen}
            toggleDrawer={toggleDrawer}
            closeDrawer={closeDrawer}
            isPremiumPro={state.isPremiumPro}
          />
        )}
      </div>
    </Fragment>
  );
};

export default withWidth()(MenuAndAppBar);

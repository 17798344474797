import React from "react";
import {
  AlcoolLevelProvider,
  StarterToolProvider,
  BottlesCounterProvider,
  DensityCorrectionProvider,
  CarbonatationProvider,
  DensityRefractoProvider,
  SanitiserToolProvider,
  YieldToolProvider,
  GravityAdjustProvider,
  TheoricalDensityProvider,
  IbuToolProvider,
  EmpatageToolProvider,
  EmpatageToolStdProvider
} from "beerFactoryTool/dist/uiTools";
import { useAuth0 } from "./lib/react-auth0-wrapper";
import CircularProgress from "@material-ui/core/CircularProgress";
import RecipeProvider from "./Pages/Recipe/RecipeProvider";
import IOTDevicesProvider from "./Pages/Iot/IOTDevicesProvider";

const GlobalProvider = ({ ...props }) => {
  const { loading } = useAuth0();

  return loading ? (
    <div
      style={{
        background: "rgba(0,0,0,0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 99
      }}
    >
      <CircularProgress
        style={{
          marginTop: "calc(50vh - 20px)",
          marginLeft: "calc(50vw - 20px)"
        }}
      />
    </div>
  ) : (
    <AlcoolLevelProvider>
      <StarterToolProvider>
        <BottlesCounterProvider>
          <DensityRefractoProvider>
            <CarbonatationProvider>
              <DensityCorrectionProvider>
                <GravityAdjustProvider>
                  <SanitiserToolProvider>
                    <TheoricalDensityProvider>
                      <IbuToolProvider>
                        <RecipeProvider>
                          <YieldToolProvider>
                            <EmpatageToolProvider>
                              <EmpatageToolStdProvider>
                                <IOTDevicesProvider>
                                  {props.children}
                                </IOTDevicesProvider>
                              </EmpatageToolStdProvider>
                            </EmpatageToolProvider>
                          </YieldToolProvider>
                        </RecipeProvider>
                      </IbuToolProvider>
                    </TheoricalDensityProvider>
                  </SanitiserToolProvider>
                </GravityAdjustProvider>
              </DensityCorrectionProvider>
            </CarbonatationProvider>
          </DensityRefractoProvider>
        </BottlesCounterProvider>
      </StarterToolProvider>
    </AlcoolLevelProvider>
  );
};

export default GlobalProvider;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const VintageIcon = ({ width, height, color = "#000000" }) => (
  <SvgIcon
    style={{ width, height }}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="363.000000pt"
    height="525.000000pt"
    viewBox="0 0 363.000000 525.000000"
    preserveAspectRatio="xMidYMid meet"
    strategy="component"
  >
    <g
      transform="translate(0.000000,525.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path
        d="M772 5093 c-120 -3 -144 -6 -149 -20 -3 -8 -1 -24 5 -34 9 -15 7 -23
-13 -47 -26 -31 -33 -76 -13 -88 24 -15 -25 -1005 -63 -1254 -21 -139 -84
-289 -187 -439 -73 -108 -113 -188 -138 -276 -16 -54 -18 -141 -21 -850 -2
-434 0 -903 4 -1042 l8 -252 48 -49 c97 -98 286 -188 477 -227 65 -13 115 -16
210 -12 69 2 132 7 140 11 13 5 15 129 15 1029 0 958 1 1026 18 1086 23 82 75
185 132 263 51 68 145 250 145 279 0 10 -28 62 -61 114 -40 63 -74 133 -99
203 -41 118 -46 163 -70 707 -22 504 -23 688 -7 719 17 32 9 62 -19 73 -11 4
-14 18 -12 51 4 65 22 63 -350 55z"
      />
      <path
        d="M2702 5093 c-120 -3 -144 -6 -149 -20 -3 -8 -1 -24 5 -34 9 -15 7
-23 -13 -47 -26 -31 -33 -76 -13 -88 24 -15 -25 -1005 -63 -1254 -18 -124 -79
-275 -158 -398 -23 -34 -41 -68 -41 -75 0 -23 66 -150 122 -237 73 -111 100
-167 132 -269 25 -78 27 -102 36 -380 6 -163 10 -624 9 -1026 l0 -730 73 -17
c85 -19 258 -23 344 -7 205 36 386 148 451 278 17 33 18 83 21 710 4 846 -9
1354 -38 1458 -29 104 -58 165 -145 303 -55 87 -89 153 -115 229 -41 117 -46
163 -70 706 -22 504 -23 688 -7 719 17 32 9 62 -19 73 -11 4 -14 18 -12 51 4
65 22 63 -350 55z"
      />
      <path
        d="M1742 4753 c-120 -3 -144 -6 -149 -20 -3 -8 -1 -24 5 -34 9 -15 7
-23 -13 -47 -26 -31 -33 -76 -13 -88 24 -15 -25 -1005 -63 -1254 -21 -139 -84
-289 -187 -439 -73 -108 -113 -188 -138 -276 -16 -54 -18 -141 -21 -850 -2
-434 0 -903 4 -1042 l8 -252 45 -46 c94 -97 271 -183 460 -226 96 -21 253 -25
346 -8 205 36 386 148 451 278 17 33 18 83 21 710 4 846 -9 1354 -38 1458 -29
104 -58 165 -145 303 -55 87 -89 153 -115 229 -41 117 -46 163 -70 706 -22
504 -23 688 -7 719 17 32 9 62 -19 73 -11 4 -14 18 -12 51 4 65 22 63 -350 55z"
      />
      <path
        d="M2530 1329 c0 -403 -3 -744 -6 -758 -4 -25 -4 -25 5 -4 11 23 17
1480 7 1490 -2 3 -5 -325 -6 -728z"
      />
    </g>
  </SvgIcon>
);

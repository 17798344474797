import React from "react";
import { CircularProgress } from '@material-ui/core';

const Loading = () => (
  <div
    style={{
      background: "rgba(0,0,0,0.5)",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      zIndex: 99
    }}
  >
    <CircularProgress
      style={{
        marginTop: "calc(50vh - 20px)",
        marginLeft: "calc(50vw - 20px)"
      }}
    />
  </div>
);

export default Loading;

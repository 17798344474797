import axios from "axios";
import React, { Fragment } from "react";
import swal from "@sweetalert/with-react";
import { Translation } from "react-i18next";

let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async function (config) {
    let headers = {};
    let token = localStorage.getItem("token");
    if (token) {
      headers.Authorization = "Bearer " + token;
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      };
    }
    return {
      ...config,
      headers,
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      swal({
        content: (
          <div>
            <Translation>
              {(t, { i18n }) => (
                <Fragment>
                <h2>{t("UnknownIssue")}</h2>
                <p>{t("UnknownIssueMessage")}</p>
              </Fragment>
              )}
            </Translation>
          </div>
        ),
        icon: "warning",
      });
    }
    else if (401 === error.response.status) {
      swal({
        content: (
          <div>
            <Translation>
              {(t, { i18n }) => (
                <Fragment>
                  <h2>{t("NotConnectedSWALTitle")}</h2>
                  <p>{t("NotConnectedSWALMessage")}</p>
                </Fragment>
              )}
            </Translation>
          </div>
        ),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location = `${process.env.PUBLIC_URL}/renewAuth`;
        } else {
          window.location = `${process.env.PUBLIC_URL}/home`;
        }
      });
    } else if (500 === error.response.status) {
      swal({
        content: (
          <div>
            <Translation>
              {(t, { i18n }) => (
                <Fragment>
                  <h2>{t("UnknownIssue")}</h2>
                  <p>{t("UnknownIssueMessage")}</p>
                </Fragment>
              )}
            </Translation>
          </div>
        ),
        icon: "warning",
        dangerMode: true,
      });
    } else {
      console.log("GENERIRC ERROR");
      return Promise.reject(error);
    }
  }
);

export { axiosInstance };

import { createMuiTheme, createStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import palette from "./palette";

const defaultConfig = {
  palette,
  spacing: {
    unit: 8,
    topHeader: 20
  },
  sizing: {
    iconCardSize: 24
  }
};

const theme = createMuiTheme({
  ...defaultConfig,
  spacing: 8,
  overrides: {
    MuiCard: {
      root: {
        boxShadow: "0 4px 8px 0 rgba(0,0,0,.12), 0 2px 4px 0 rgba(0,0,0,.08)"
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});

export const basicContentPart = {
  maxWidth: "780px",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginLeft: "auto",
  marginRight: "auto",
  flex: 1,
  minWidth: "300px",
  padding: "1rem",
  color: "#3d3e2f"
};

export const defaultStyle = makeStyles(theme =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.primary.main
    },
    controlToolBar: {
      background: "white",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)",
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      marginBottom: theme.spacing(2),
      alignItems: "center",
      borderRadius: "1rem",
      display: "table",
      marginLeft: "auto",
      "@global button": {
        fontSize: ".88rem",
        fontWeight: 300,
        textTransform: "none"
      }
    },
    defaultTabs: {
      color: "#ffd99e",
      background: "#484848"
    },
    errorMessage: {
      padding: "50px 20px",
      textAlign: "center",
      margin: "20px 40px 20px 40px",
      height: "auto",
      border: "1px solid",
      color: defaultConfig.palette.secondary.main,
      borderColor: defaultConfig.palette.primary.main,
      borderRadius: "10px"
    },
    card: {
      ...basicContentPart,
      padding: theme.spacing(2)
    },
    progress: {
      padding: "40px",
      textAlign: "center"
    },
    progressLazy: {
      padding: "100px",
      textAlign: "center"
    },
    noData: {
      marginTop: "100px",
      textAlign: "center"
    },

    validButton: {
      flex: 1,
      maxWidth: "150px",
      color: "black",
      background: theme.palette.primary.main,
      border: "3px solid",
      borderColor: theme.palette.primary.main,
      marginLeft: "auto",
      borderRadius: 0
    },
    disabledButton: {
      flex: 1,
      maxWidth: "150px",
      color: "black",
      borderRadius: 0,
      background: "rgba(255, 189, 106, 0.68)",
      border: "3px solid",
      borderColor: "rgba(255, 189, 106, 0.68)",
      marginLeft: "auto"
    },
    cancelButton: {
      flex: 1,
      color: theme.palette.primary.main,
      border: "3px solid",
      maxWidth: "150px",
      marginRight: "auto",
      borderRadius: 0
    },
    callToAction: {
      flex: 1,
      color: "#717171",
      fontSize: "0.7rem",
      background: "#ffbd6aa3",
      boxShadow: "none",
      transition: "all .2s ease-in-out",
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      "&:hover": {
        transform: "scale(1.02)"
      },
      minWidth: "130px",
      maxWidth: "200px"
    },
    fab: {
      margin: theme.spacing(4),
      position: "fixed",
      bottom: 0,
      right: 0
    },
    addIngredientRecipe: {
      float: "right",
      backgroundColor: theme.palette.secondary.main,
      border: "none",
      borderRadius: 0,
      color: theme.palette.primary.main,
    },
  })
);

theme.overrides.MuiToggleButton = {
  root: {
    color: theme.palette.primary.main,
    background: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.primary.main
    },
    "&$selected": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        border: "1px solid transparent"
      }
    }
  }
};

export default theme;

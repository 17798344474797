const translation = {
  translation: {
    Malts: "Malts",
    Name: "Nom",
    Quantity: "Quantité",
    NoMalts: "Aucun malt ou fermenticible dans cette recette.",
    NoHops: "Aucun houblon dans cette recette.",
    NoYeasts: "Aucune levure dans cette recette.",
    Miscs: "Aucun ingrédient spécifique dans cette recette.",
    Fermentables: "Fermenticibles",
    Grain: "Grain",
    AddFermenticibles: "Ajouter un ingrédient",
    AvailableFermentables: "Ingrédients disponibles",
    addCustomIngredients: "Ajouter un ingrédient non listé",
    Cancel: "Annuler",
    Weight: "Poids",
    Yield: "Rendement",
    Color: "Couleur",
    Origin: "Origine",
    Type: "Type",
    Sugar: "Sucre",
    Extract: "Extrait sec",
    DyrExtract: "Extrait liquide",
    Adjunct: "Complèment",
    Delete: "Supprimer",
    DeleteIngredient: "Voulez-vous supprimer cet ingrédient ?",
    Validate: "Valider",
    Zoom: "Zoom",
    Rotation: "Rotation",
    NoFermentables: "Aucun fermenticible n'est disponible.",
    addFermentableStep: "Ajouter une étape",
    unit: "Unité",
    Notes: "Notes",
    FermentationDuration: "Durée de fermentation",
    FermentationTemp: "Tempèrature de fermentation",
    quitAndNoSaveRecipe:
      "Vous n'avez pas sauvegardé vos modifications. Voulez-vous vraiment quitter la page?",
    ChangePic: "Changez la photo",
    fileNotSupportedImageOnly:
      "Ce fichier n'est pas supporter. Veuillez choisir un image png, jpg, jpeg ou gif.",
    unknownError: "Oups, une erreur c'est produite.",
    FermentablesTotal: "Quantité totale",
    HopsTotal: "Quantité totale",
    Yeasts: "Levures",
    RatingRecipe: "Notez la recette",
    Comment: "Commentaire",
    Title: "Titre",
    UnknownIssue: "Problème inconnu",
    UnknownIssueMessage:
      "Oups, un problème est survenu. Si cette erreur persiste contactez un administrateur",
    NotConnectedSWALTitle: "Vous êtes déconnecté",
    NotConnectedSWALMessage: "Voulez-vous vous reconnecter?",
    conditoning: "Conditionnement",
  },
};

export default translation;

import React from "react";
import { defaultStyle } from "../theme";
import Typography from "@material-ui/core/Typography";
import BrokenBottle from "../icons/brokenBottle/BrokenBottle";
import { useSpring, animated } from "react-spring";

const NotFoundBlock = ({ error }) => {
  const defaultClasses = defaultStyle();
  const props = useSpring({
    config: { duration: 1000 },
    opacity: 1,
    from: { opacity: 0 }
  });

  return (
    <div className={defaultClasses.errorMessage}>
      <animated.div style={props}>
        <BrokenBottle />
      </animated.div>
      <Typography style={{ marginTop: 10 }} component="h6" variant="h6">
        Il semble que vous vous êtes perdu, cette page n'existe pas!
      </Typography>
    </div>
  );
};
export default NotFoundBlock;

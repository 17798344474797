import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link, useLocation, useHistory } from "react-router-dom";
import { AppRoutes } from "../../routes";
import MenuAndAppBar from "./MenuAndAppBar";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { Build, School, Home } from "@material-ui/icons";
import { BrasserieIcon } from "../../icons/brasserieIcon";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
  },
  contentPermanent: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
      flexGrow: 1,
      minHeight: "calc(100vh - 154px)",
      marginLeft: drawerWidth,
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: 56,
      flexGrow: 1,
      minHeight: "calc(100vh - 154px)",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: 64,
      flexGrow: 1,
      minHeight: "calc(100vh - 154px)",
      marginLeft: drawerWidth,
    },
  },

  footer: {
    marginTop: "20px",
    height: "50px",
    borderTop: "solid 1px #d0cfcf",
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  footerLink: {
    textDecoration: "none",
    color: "#484848",
    alignSelf: "center",
    padding: "10px",
    textAlign: "right",
  },
}));

const PageContainer = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let isHome = location.pathname.includes("home");
    if (isHome) {
      setIndex(0);
    }
    let isTools = location.pathname.includes("tools");
    if (isTools) {
      setIndex(1);
    }
  });

  const handleChange = (event, newValue) => {
    setIndex(newValue);
    if (newValue === 0) {
      history.push(`${process.env.PUBLIC_URL}/home`);
    }
    if (newValue === 1) {
      history.push(`${process.env.PUBLIC_URL}/tools`);
    }
    if (newValue === 2) {
      history.push(`${process.env.PUBLIC_URL}/myBrewery`);
    }
    if (newValue === 3) {
      history.push(`${process.env.PUBLIC_URL}/learn`);
    }
  };

  let isEmbedded = location.pathname.includes("shops-embedded");

  if (isEmbedded) {
    return (
      <div>
        <AppRoutes />
      </div>
    );
  }

  if (isWidthDown("sm", props.width)) {
    return (
      <div>
        <MenuAndAppBar />
        <AppBar position="fixed" className={classes.appBar} color="primary">
          <Tabs
            value={index}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
          >
            <Tab icon={<Home />} />
            <Tab icon={<Build />} />
            <Tab icon={<BrasserieIcon />} />
            <Tab icon={<School />} />
          </Tabs>
        </AppBar>
        <div>
          <main className={classes.contentPermanent}>
            <AppRoutes />
          </main>

          <div className={classes.footer}>
            <a
              href="https://blog.mybeerfactory.fr"
              className={classes.footerLink}
            >
              Le blog
            </a>
            <Link
              to={`${process.env.PUBLIC_URL}/release`}
              className={classes.footerLink}
            >
              Release et futures nouveautés
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/cgu`}
              className={classes.footerLink}
            >
              CGU
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/credit`}
              className={classes.footerLink}
            >
              Crédit
            </Link>
            <span className={classes.footerLink}>2020 © Beer factory</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <MenuAndAppBar />
      <div>
        <main className={classes.contentPermanent}>
          <AppRoutes />
        </main>
        <div className={classes.footer}>
          <a
            href="https://blog.mybeerfactory.fr"
            className={classes.footerLink}
          >
            Le blog
          </a>
          <Link
            to={`${process.env.PUBLIC_URL}/release`}
            className={classes.footerLink}
          >
            Release et futures nouveautés
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/cgu`}
            className={classes.footerLink}
          >
            CGU
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/credit`}
            className={classes.footerLink}
          >
            Crédit
          </Link>
          <span className={classes.footerLink}>2020 © Beer factory</span>
        </div>
      </div>
    </div>
  );
};
export default withWidth()(PageContainer);

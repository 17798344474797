import React, { createContext } from "react";
import { axiosInstance as axios } from "../../axiosInstance";
import { useHistory } from "react-router-dom";

const initialVal = {
  currentDevice: {},
  devices: [],
  isFetching: true,
  data: [],
  isOpen: false,
  type: "",
  token: "",
  from: "",
  to: "",
  moreInfoIsOpen: false,
  deleteDialogIsOpen: false,
  addDeviceModalIsShow: false
};

export const IOTDevicesContext = createContext({
  ...initialVal,
  fetchDevices: () => {},
  selectDevice: () => {},
  validForm: () => {}
});

const { Provider, Consumer } = IOTDevicesContext;

function useCustomHook(defState) {
  const [state, setState] = React.useState(defState);

  function smartSetState(newState) {
    if (
      state.currentDevice !== newState.currentDevice ||
      state.devices !== newState.devices ||
      state.isFetching !== newState.isFetching ||
      state.data !== newState.data ||
      state.isOpen !== newState.isOpen ||
      state.type !== newState.type ||
      state.token !== newState.token ||
      state.from !== newState.from ||
      state.to !== newState.to ||
      state.moreInfoIsOpen !== newState.moreInfoIsOpen ||
      state.deleteDialogIsOpen !== newState.deleteDialogIsOpen ||
      state.addDeviceModalIsShow !== newState.addDeviceModalIsShow
    ) {
      setState(newState);
    }
  }

  return [state, smartSetState];
}

const IOTDevicesProvider = ({ ...props }) => {
  const [state, setState] = useCustomHook({ ...initialVal });
  const history = useHistory();

  async function fetchDevices() {
    setState({ ...state, isFetching: true });

    let devices = await axios.get("/devices");
    setState({
      ...state,
      isFetching: false,
      devices: devices.data,
      moreInfoIsOpen: false,
      openDeleteDialog: false,
      addDeviceModalIsShow: false
    });
  }

  async function handleDelete(device) {
    await axios.delete("/devices/" + device.id);
    fetchDevices();
  }

  async function validForm({ ev, token, from, to, deviceId, type }) {
    ev.preventDefault();
    localStorage.setItem("IOTDevice", token);
    localStorage.setItem("IOTFrom", from);
    localStorage.setItem("IOTTo", to);
    localStorage.setItem("deviceId", deviceId);
    setState({ ...state, data: [] });
  }

  function showDeleteDeviceModal(o) {
    if (state.openDeleteDialog !== o) {
      setState({ ...state, openDeleteDialog: o });
    }
  }

  function showAddDeviceModal(o) {
    if (state.addDeviceModalIsShow !== o) {
      setState({ ...state, addDeviceModalIsShow: o });
    }
  }
  function showMoreInfoModal(o, device) {
    if ((state.moreInfoIsOpen !== o, state.currentDevice !== device)) {
      setState({ ...state, moreInfoIsOpen: o, currentDevice: device });
    }
  }
  function setIsFetching(o) {
    if (state.isFetching !== o) {
      setState({ ...state, isFetching: o });
    }
  }

  async function handleSave(device) {
    setIsFetching(true);
    await axios.post("/devices", device);
    let devices = await axios.get("/devices");
    setState({
      ...state,
      isFetching: false,
      devices: devices.data,
      moreInfoIsOpen: false,
      openDeleteDialog: false,
      addDeviceModalIsShow: false
    });
  }

  const setCurrentDevice = d => {
    setState({ ...state, currentDevice: d });
  };

  const selectDevice = device => {
    history.push(
      `${process.env.PUBLIC_URL}/device/${device.deviceId}/${device.type}`
    );
  };
  return (
    <Provider
      value={{
        ...state,
        handleDelete,
        validForm,
        showAddDeviceModal,
        showDeleteDeviceModal,
        showMoreInfoModal,
        setCurrentDevice,
        handleSave,
        selectDevice,
        fetchDevices
      }}
    >
      {props.children}
    </Provider>
  );
};

export const withIOTDevices = Component => props => (
  <Consumer>{store => <Component {...props} {...store} />}</Consumer>
);

export default IOTDevicesProvider;

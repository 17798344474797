import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BrokenBottle = ({ width = 100, height = 100 }) => (
  <SvgIcon
    style={{ width, height }}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 525"
    preserveAspectRatio="xMidYMid meet"
    strategy="component"
  >
    <g>
      <path
        d="M501.445,167.278l-32.58,9.667l41.904-64.384c1.793-2.755,1.614-6.349-0.442-8.913c-2.057-2.564-5.526-3.518-8.605-2.364
			l-41.514,15.551l18.536-30.55c1.637-2.698,1.439-6.124-0.496-8.616c-1.935-2.491-5.205-3.529-8.225-2.612L368.805,105.87
			l31.505-61.193c1.551-3.012,0.923-6.687-1.539-9.013c-2.462-2.326-6.167-2.745-9.087-1.027l-50.731,29.861l13.966-40.339
			c1.043-3.012,0.097-6.355-2.368-8.375s-5.929-2.288-8.679-0.674l-44.913,26.4l4.471-15.853c0.94-3.333-0.488-6.88-3.476-8.631
			c-2.987-1.75-6.78-1.264-9.229,1.184l-74.798,74.798c-2.972,2.972-2.972,7.79,0,10.761c2.972,2.972,7.79,2.972,10.761,0
			l54.177-54.177l-17.833,63.225c-0.844,2.991,0.216,6.194,2.677,8.092c2.46,1.897,5.828,2.107,8.506,0.532l102.523-60.348
			l-27.708,53.818c-1.4,2.719-1.036,6.01,0.923,8.358c1.958,2.348,5.133,3.295,8.058,2.405l99.388-30.255l-64.805,106.809
			c-1.631,2.689-1.441,6.104,0.48,8.593c1.921,2.49,5.175,3.545,8.19,2.648l77.756-23.07l-99.346,99.346
			c-0.007,0.006-0.013,0.013-0.02,0.02l-55.406,55.406c-13.702,13.702-31.005,22.87-50.037,26.511
			c-19.033,3.64-38.496,1.51-56.289-6.167l-13.411-5.785c-2.863-1.236-6.19-0.599-8.395,1.606l-87.929,87.929l-57.516-57.516
			l87.93-87.929c2.205-2.205,2.842-5.531,1.606-8.395l-5.785-13.411c-7.676-17.793-9.809-37.257-6.168-56.289
			c3.641-19.032,12.809-36.335,26.511-50.037l48.332-48.332c2.972-2.972,2.972-7.79,0-10.761c-2.972-2.972-7.79-2.972-10.761,0
			l-48.332,48.332c-15.866,15.866-26.481,35.901-30.697,57.938c-4.217,22.038-1.748,44.576,7.141,65.177l3.74,8.667l-84.597,84.597
			c-4.281-2.355-9.778-1.729-13.404,1.896L3.296,404.098c-4.394,4.394-4.394,11.544,0,15.938l40.296,40.296
			c2.972,2.972,7.79,2.972,10.761,0c2.972-2.972,2.972-7.79,0-10.761l-37.504-37.504l15.311-15.311l0.373,0.373l68.277,68.277
			l0.373,0.373l-15.311,15.311l-11.735-11.734c-2.972-2.973-7.79-2.972-10.761,0c-2.972,2.972-2.972,7.79,0,10.761l14.527,14.526
			c2.198,2.198,5.083,3.296,7.969,3.296c2.885,0,5.772-1.099,7.968-3.296l20.894-20.894c3.625-3.625,4.251-9.122,1.895-13.404
			l84.597-84.596l8.669,3.74c20.602,8.888,43.14,11.357,65.177,7.141c22.038-4.217,42.072-14.832,57.938-30.698l175.978-175.978
			c2.462-2.462,2.939-6.28,1.159-9.272C508.367,167.689,504.783,166.286,501.445,167.278z M318.726,76.403l-37.902,22.31
			l10.166-36.043l40.792-23.978L318.726,76.403z M413.828,193.274l33.618-55.406l37.944-14.214l-39.031,59.968L413.828,193.274z"
      />
    </g>
  </SvgIcon>
);

export default BrokenBottle;

import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { axiosInstance as axios } from "../axiosInstance";
var OneSignal = window.OneSignal || null;

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const initialState = {
  isAuthenticated: false,
  user: null,
  auth0Client: null,
  loading: true,
  popupOpen: false,
  proGroups: [],
  roles: [],
  offers: [],
  token: []
};

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [state, setState] = useState(initialState);
  /*const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [proGroups, setproGroups] = useState([]);
  const [roles, setRoles] = useState();
  const [offers, setOffers] = useState();
  const [token, setToken] = useState();*/

  const setPopupOpen = popupOpen => setState({ ...state, popupOpen });
  const setLoading = loading => setState({ ...state, loading });

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      let info = {};
      if (isAuthenticated) {
        info = await updateUser(auth0FromHook); // NEW
      }
      setState({
        ...state,
        ...info,
        loading: false,
        auth0Client: auth0FromHook,
        isAuthenticated
      });
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await state.auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
      setPopupOpen(false);
    }
    const user = await state.auth0Client.getUser();
    setState({ ...state, popupOpen: false, isAuthenticated: true, user });
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    let info = await updateUser(state.auth0Client); // NEW
    await state.auth0Client.handleRedirectCallback();
    const user = await state.auth0Client.getUser();
    setState({
      ...state,
      ...info,
      loading: false,
      isAuthenticated: false,
      user
    });
  };

  // NEW
  const updateUser = async client => {
    try {
      let tempUser = await client.getUser();
      let t = await client.getTokenSilently();
      localStorage.setItem("token", t);

      if (tempUser) {
        tempUser = await createOrUpdateUser(tempUser, t);
        let createdUser = tempUser.data;
        let pg = {};
        if(createdUser && createdUser.proGroups && createdUser.proGroups.length >0)
        {
          pg = await axios.get("/pro-groups/" + createdUser.proGroups[0]._id);
        }
        return {
          token: t,
          user: createdUser,
          proGroups: [pg.data],
          roles: createdUser.roles,
          offers: createdUser.offers
        };
      } else {
        console.error("no user");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const createOrUpdateUser = async (profile, token) => {
    let query = "/users";
    let pushTagID = "";
    let res;
    try {
      localStorage.setItem("version", "new");
      if (OneSignal && OneSignal.getUserId) {
        if (
          isFunction(OneSignal.getUserId) &&
          navigator.userAgent.indexOf("Safari") === -1
        ) {
          //pushTagID = await OneSignal.getUserId();
        } else {
          console.error("Erreur de chargement de notif push");
        }
      }
      res = await axios.post(
        query,
        { ...profile, pushTagID },
        { headers: { Authorization: "Bearer " + token } }
      );

      return res;
    } catch (err) {
      console.error("ERRREEDDDS",err);
    }
  };

  const isFunction = variableToCheck => {
    //If our variable is an instance of "Function"
    if (variableToCheck instanceof Function) {
      return true;
    }
    return false;
  };

  return (
    <Auth0Context.Provider
      value={{
        ...state,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => state.auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => state.auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => state.auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => state.auth0Client.getTokenWithPopup(...p),
        logout: (...p) => {
          localStorage.setItem("lastPage", "/");
          setLoading(false);
          if (p) {
            state.auth0Client.logout({
              ...p,
              returnTo: process.env.REACT_APP_LOGOUT_URL
            });
          } else {
            state.auth0Client.logout({
              returnTo: process.env.REACT_APP_LOGOUT_URL
            });
          }
        }
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

import React from "react";
import PageContainer from "./Components/Layout/PageContainer";
import { useAuth0 } from "./lib/react-auth0-wrapper";
import history from "./history";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import { Router, Prompt } from "react-router-dom";
import GlobalProvider from "./GlobalProvider";
import Loading from "./Components/Loading";

const App = () => {
  const { loading } = useAuth0();
  console.log("App reload", loading);
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Loading />
      </ThemeProvider>
    );
  } else {
    return (
      <Router history={history}>
        <Prompt
          when={false}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <ThemeProvider theme={theme}>
          <GlobalProvider>
            <PageContainer />
          </GlobalProvider>
        </ThemeProvider>
      </Router>
    );
  }
};

export default App;

import React, { createContext, useState, useEffect, useCallback } from "react";
import { axiosInstance as axios } from "../../axiosInstance";
import { useAuth0 } from "../../lib/react-auth0-wrapper";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { generateErrorObject } from "../../utils/errorGenerator";
import isEqual from "lodash/isEqual";
import {
  calculateIbu,
  getTheoricalDenity,
  getTheoricalDF,
  getColor,
  getAlcohoLevel,
} from "beerFactoryTool/dist/libTools";

const _ = { map, isEqual, isEmpty };
export const mode = {
  PRIVATE: "private",
  PUBLIC: "public",
  PROGROUP: "pro-group",
};

const initialVal = {
  favRecipes: [],
  sortCriteria: "name",
  isFetching: true,
  isBlocking: false,
  filterCriteria: "",
  isOwner: false,
  hasMore: true,
  recipeId: null,
  recipe: { isValid: true, isPublic: false },
  style: {},
  lastPageLoaded: 0,
  activeIndex: -1,
  error: null,
  selectedMode: "",
  openSuccess: false,
  needToBeSaved: false,
};

export const RecipeContext = createContext({
  ...initialVal,
  addToFav: () => {},
});

const RecipeProvider = ({ ...props }) => {
  const { user, proGroups } = useAuth0();
  const [state, setState] = useState({ ...initialVal });
  const [id, setId] = useState("");
  const selectedProGroup = proGroups ? proGroups[0] : {};

  useEffect(() => {
    if (user && state.recipe) {
      setOwner(state.recipe.author && user.sub === state.recipe.author.sub);
    }
  }, [user, state.recipe]);

  useEffect(() => {
    if (id && id !== state.recipeId) {
      fetchRecipe(id);
    }
  }, [id]);

  const setOpenSuccess = (openSuccess) => {
    if (openSuccess !== state.openSuccess) {
      setState({ ...state, openSuccess });
    }
  };
  const setRecipe = (recipe) => {
    if (recipe && !_.isEqual(recipe, state.recipe)) {
      let newOg = getTheoricalDenity(
        parseFloat(recipe.efficiency),
        recipe.batch_size ? recipe.batch_size.value : 0,
        recipe.ingredients ? recipe.ingredients.fermentables : []
      ).di.value;

      let newFg = getTheoricalDF(recipe.og || 1.01, recipe.ingredients.yeasts);
      if (newFg && newFg.df) {
        newFg = newFg.df.value;
      } else {
        newFg = null;
      }
      let newIbu = 0;
      if (recipe && recipe.batch_size) {
        newIbu = calculateIbu(
          recipe.ingredients.hops,
          newFg,
          recipe.batch_size.value
        ).ibu;
      }

      let color = { value: 0, unit: "EBC" };
      if (
        recipe &&
        recipe.batch_size &&
        recipe.ingredients &&
        recipe.ingredients.fermentables
      ) {
        color.value = getColor(
          recipe.ingredients.fermentables,
          parseFloat(recipe.batch_size.value)
        ).ebc;
      }

      const alcoholLevel = getAlcohoLevel(newOg, newFg, 0);
      let newAbv = alcoholLevel.levelBeforeBottle;
      let newAttenuation = alcoholLevel.attenuation;

      setState({
        ...state,
        needToBeSaved: true,
        isPublic: recipe.isPublic,
        recipe: {
          ...recipe,
          abv: newAbv,
          attenuation_level: newAttenuation,
          ibu: newIbu,
          color,
          og: newOg,
          fg: newFg,
        },
      });
    }
  };

  const setOwner = (isOwner) => {
    if (isOwner !== state.isOwner) {
      setState({ ...state, isOwner });
    }
  };
  const setIsFetching = (isFetching) => {
    if (isFetching !== state.isFetching) {
      setState({ ...state, isFetching });
    }
  };

  const toggleShareButton = useCallback(async () => {
    let query = process.env.PUBLIC_URL + "/recipes/" + state.recipe._id;

    let r = await axios.post(query, {
      ...state.recipe,
      isPublic: !state.recipe.isPublic,
    });
    if (r.data && !_.isEqual(r.data, state.recipe)) {
      setState({ ...state, recipe: r.data, isFetching: false });
    }
  }, [state.recipe, state.isFetching]);

  const resetRecipes = () => {
    setState({ ...state, recipes: [], lastPageLoaded: 0 });
  };

  const updateComments = async (comments) => {
    if (!_.isEmpty(comments)) {
      try {
        let url = "/recipes";
        if (state.selectedMode === mode.PROGROUP) {
          url += "?proGroup=" + selectedProGroup._id;
        }

        let updatedRecipe = await axios.post(url, {
          ...state.recipe,
          comments,
        });
        if (updatedRecipe)
          setState({
            ...state,
            recipe: { ...updatedRecipe.data.recipe },
            needToBeSaved: false,
            isBlocking: false,
            openSuccess: true,
          });
      } catch (err) {
        console.error("ERROR => ", err);
        setState({
          ...state,
          isFetching: false,
          needToBeSaved: false,
          error: "err",
        });
      }
    }
  };

  const saveRecipe = async () => {
    if (!_.isEmpty(state.recipe)) {
      try {
        let url = "/recipes";
        if (state.selectedMode === mode.PROGROUP) {
          url += "?proGroup=" + selectedProGroup._id;
        }

        let style = state.recipe.style;
        let styles = [];
        if (!style && state.recipe.styles && state.recipe.styles.length > 0) {
          style = state.recipes.style[0];
          styles = [];
        }

        let updatedRecipe = await axios.post(url, {
          ...state.recipe,
          style,
          styles,
        });
        if (
          updatedRecipe.data.recipe &&
          !_.isEqual(updatedRecipe.data.recipe, state.recipe)
        ) {
          setState({
            ...state,
            recipe: updatedRecipe.data.recipe,
            needToBeSaved: false,
            isBlocking: false,
            openSuccess: true,
          });
        }
      } catch (err) {
        console.error(err);
        setState({
          ...state,
          isFetching: false,
          needToBeSaved: false,
          error: "err",
        });
      }
    }
  };

  const fetchRecipe = async (id) => {
    try {
      if (!state.error || !state.isFetching) {
        setState({
          ...state,
          error: null,
          isFetching: true,
        });
      }
      const res = await axios.get("/recipes/" + id);
      let rec = res.data.recipe;
      let unit;

      if (rec.batch_size) {
        unit = rec.batch_size.unit === "litres" ? "L" : rec.batch_size.unit;
        unit = rec.batch_size.unit === "litre" ? "L" : rec.batch_size.unit;

        rec.batch_size = {
          value: rec.batch_size.value,
          unit,
        };
      } else if (rec.volume) {
        unit = rec.volume.unit === "litres" ? "L" : rec.volume.unit;
        unit = rec.volume.unit === "litre" ? "L" : rec.volume.unit;

        rec.batch_size = {
          value: rec.volume.value,
          unit,
        };
      }

      if (rec && !_.isEqual(rec, state.recipe)) {
        setState({
          ...state,
          recipeId: id,
          isFetching: false,
          error: null,
          needToBeSaved: false,
          recipe: rec,
        });
      }
    } catch (err) {
      console.log(
        "Recipe providers error: ",
        generateErrorObject(err && err.response ? err.response.status : 500)
      );
      setState({
        ...state,
        isFetching: false,
        needToBeSaved: false,
        error: generateErrorObject(
          err && err.response ? err.response.status : 500
        ),
      });
    }
  };

  return (
    <RecipeContext.Provider
      value={{
        ...state,
        proGroups,
        setRecipe,
        setIsFetching,
        fetchRecipe,
        saveRecipe,
        selectedProGroup,
        resetRecipes,
        toggleShareButton,
        setOwner,
        setId,
        setOpenSuccess,
        updateComments,
      }}
    >
      {props.children}
    </RecipeContext.Provider>
  );
};

export const withRecipe = (Component) => (props) => (
  <RecipeContext.Consumer>
    {(store) => <Component {...props} {...store} />}
  </RecipeContext.Consumer>
);

export default RecipeProvider;
